import React, { useEffect, useState, useLayoutEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../helpers/apiHelper/requestHelper";
import { setLoading } from "../../redux/loading/loadingSlice";
import DailyAvg from "../../assets/images/1.gif";
import businessSign from "../../assets/images/business.png";
import copyIcon from "../../assets/images/copylink_icon.svg";
import moreButton from "../../assets/images/MoreButton.svg";
import Discount from "../../assets/images/4.gif";
import X from "../../assets/images/x.jpg";
import Telegram from "../../assets/images/telegram.jpg";
import Discord from "../../assets/images/discord.svg";
import Circle from "../../assets/images/MoreButton.svg";
import mbls from "../../assets/images/mbls.png";
import { toastify } from "../../helpers/toast/toastify";
import TradeHistoryChart from "./TradeHistoryChart";
import RenderLinks from "../links/RenderLinks";
import { useNavigate } from "react-router-dom";
import TradeGraph from "../../assets/images/graph.svg";
import { setCurrentTab } from "../../redux/links/linksSlice";
import { Link } from "react-router-dom";
import { storeUser } from "../../redux/users/usersSlice";
import { simplifyTradingVolume } from "../../helpers/helper";


export default function DashboardStats({ member }) {
    const [stats, setStats] = useState({});
    const { currentTab } = useSelector(state => state.links)
    const api = useApi();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((value) => value?.user?.user);

    const getUserStats = async () => {
        const response = await api("get", "stats/index");
        if (response?.status) {
            setStats(response?.data);
        }
        // else {
        //     toastify(response?.message);
        // }
    };

    console.log(user, "UserClID")
    const updateUserBalnce = async () => {
        const response = await api("get", "stats/updateUserBalance", { id: user?._id });
    };

    useEffect(() => {

        getUserStats();
        updateUserBalnce()
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Optional: Adds smooth scrolling animation
        });
    }, []);

    // useLayoutEffect(() => {
    //  if(user?.firsVisit) {
    //     navigate("copyTrade")
    //  }
    // },[])

    const community = useSelector(
        (state) => state?.user?.user?.leaderCommunity
    );

    const formatValueWithPlus = (value) => {
        if (value === null) {
            return "0";
        }

        const numericValue = parseFloat(value);
        if (isNaN(numericValue)) {
            return "0";
        }

        return numericValue >= 0
            ? `${numericValue}`
            : numericValue;
    };

    const calculateBalance = () => {
        let totalBalance =
            parseInt(stats?.bybitBalance) + parseInt(stats?.binanceBalance);
        let weeklyBalance = stats?.balanceSinceLastWeek;

        let total = (weeklyBalance / totalBalance) * 100;

        return `${(total || 0)}%`;
    };

    console.log(stats?.balanceSinceLastWeek, "ThebalanceSinceLastWeek")

    return (
        <>
            <div className="stats_cards">
                <div className="statusTrading">
                    <p>
                        Status:{" "}
                        {user?.binanceCredentials?.isConnected ||
                            user?.bybitCredentials?.isConnected
                            ? "Trading"
                            : "Not Trading"}
                    </p>
                    <p>
                        {user?.binanceCredentials?.isConnected &&
                            user?.bybitCredentials?.isConnected
                            ? "Connected to: Binance and ByBit"
                            : user?.bybitCredentials?.isConnected
                                ? "Connected to: ByBit"
                                : user?.binanceCredentials?.isConnected
                                    ? "Connected to: Binance"
                                    : "Not Connected"}
                    </p>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="white_card">
                        <div className="h-100 d-flex flex-column justify-content-center">
                            <p className="text-truncate color-black  bold fs19 align-items-center">
                                Your Trading Account
                            </p>
                            <h4 className="text-truncate color-black bold fs30">
                                {"$"}
                                {(() => {
                                    const simplifiedValue = simplifyTradingVolume(((stats?.binanceBalance || 0) + (stats?.bybitBalance || 0)))
                                    return typeof simplifiedValue === "string" ? simplifiedValue : simplifiedValue.toFixed(2)
                                    })()}
                                {/* {} */}
                            </h4>
                            {stats?.balanceSinceLastWeek == undefined ? "" :
                                stats?.balanceSinceLastWeek != 0 ?
                                    <p className="text-truncate color-black">
                                        <span className="cyan-color plus fs14">
                                            {formatValueWithPlus(
                                                stats?.balanceSinceLastWeek || 0
                                            )}
                                            {"%"}
                                        </span>{" "}
                                        since last week ($
                                        {(stats?.balanceSinceLastWeek || 0).toFixed(2)})
                                    </p> : ""
                            }
                        </div>
                    </div>
                    <div className="black_card">
                        <div className="d-flex h-100 justify-content-between align-items-center">
                            <div className="mbl-100">
                                <p className="text-truncate  bold fs19">
                                    Daily Avg. Profit
                                </p>
                                <h4 className="text-truncate bold fs30">
                                    {"$"}
                                    {(() => {
                                         const simplifiedValue = simplifyTradingVolume((stats?.dailyAvgProfit || 0))
                                         return typeof simplifiedValue === "string" ? simplifiedValue : simplifiedValue.toFixed(2)
                                         })()}
                                </h4>
                                {stats?.averageProfitSinceLastWeek == undefined ? "" :
                                    stats?.averageProfitSinceLastWeek != 0 ?
                                        <p className="text-truncate">
                                            <span className="plus cyan-color fs14">
                                                {(stats?.averageProfitSinceLastWeek || 0).toFixed(
                                                    2
                                                )}
                                                {"%"}
                                            </span>{" "}
                                            since last week
                                        </p> : ""
                                }
                            </div>
                            <img
                                className="w-60"
                                src={DailyAvg}
                                width="100px"
                                alt="DailyAvg"
                            />
                        </div>
                    </div>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="mbl-100">
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card">
                                <p className="text-truncate  bold fs15">
                                    Winrate
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(stats?.winRate || 0).toFixed(2)}
                                    {"%"}
                                </h4>
                            </div>
                            <div className="smallBlack_card">
                                <p className="text-truncate  bold fs15">
                                    Avg. trade
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(() => {
                                         const simplifiedValue = simplifyTradingVolume((stats?.avgTrade || 0))
                                         return typeof simplifiedValue === "string" ? simplifiedValue : simplifiedValue.toFixed(2)
                                         })()}%
                                </h4>
                            </div>
                        </div>
                        <div className="black_card position-relative">
                            <p className="community_heading text-truncate  bold fs25">
                                Trade history
                            </p>
                            <p className="text-truncate medium">
                                Get a full overview
                            </p>
                            <img
                                className="graphWidth"
                                src={TradeGraph}
                                alt="graph"
                            />
                            {/* <div
                                style={{
                                    height: "100px",
                                    width: "90%",
                                }}
                            >
                                <TradeHistoryChart />
                            </div> */}
                            <div className="arrowBtn moreButtonIcon" onClick={() => {
                                navigate("/leader/performance", {
                                    state: {
                                        tab: "TradeHistory",
                                    },
                                });
                                dispatch(setCurrentTab("TradeHistory"))
                            }}>
                                <img
                                    src={moreButton}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mbl-100">
                        <div className="white_card">
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <p className="text-truncate color-black  bold fs19">
                                    Total subscribers
                                </p>
                                <h4 className="text-truncate color-black bold fs30">
                                    {user?.leaderCommunity?.participants
                                        ?.length || 0}
                                </h4>
                                {stats?.userGrowthInWeekNumber == undefined ? "" :
                                    stats?.userGrowthInWeekNumber != 0 ?
                                        <p className="text-truncate color-black">
                                            <span className="plus cyan-color fs14">
                                                + {stats?.userGrowthInWeekNumber || 0} new
                                                subs
                                            </span>{" "}
                                            since last week
                                        </p> : ""
                                }
                            </div>
                        </div>
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card last-trade-card">
                                <p className="text-truncate  bold fs15">
                                    Last trade
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(stats?.lastTrade || 0).toFixed(2)}%
                                </h4>
                            </div>
                            <div className="smallBlack_card trading-vol-group-card">
                                <p className="text-truncate  bold fs15">
                                    Trading vol. (group)
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    ${(() => {
                                         const simplifiedValue = simplifyTradingVolume((stats?.tradeAmount || 0))
                                         return typeof simplifiedValue === "string" ? simplifiedValue : simplifiedValue.toFixed(2)
                                         })()}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="white_card grey-background mbl position-relative">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="mbl-100">
                                <p className="text-truncate color-black  bold fs19 community_heading lh-24">
                                    Get your own
                                    <br />
                                    app from <br />
                                    $59/month
                                </p>
                                <button
                                    className="medium fs13"
                                    onClick={() => {
                                        navigate("/leader/app")
                                        // dispatch(setCurrentTab("order"))
                                    }}
                                >
                                    Order Now
                                </button>
                            </div>
                            <img src={mbls} alt="mbls" />
                        </div>
                    </div>
                    <div className="black_card">
                        <p className="text-truncate  bold fs25 community_heading lh-27">
                            Create a promotion
                        </p>
                        <p className="text-truncate fs14">
                            Great way to grow your business
                        </p>
                        <div className="d-flex justify-content-between align-items-end">
                            <div className="arrowBtn" onClick={() => {
                                navigate("/leader/performance", {
                                    state: {
                                        tab: "createPromotion",
                                    },
                                });
                                dispatch(setCurrentTab("createPromotion"))
                            }}>
                                <img
                                    src={Circle}
                                    alt="circle"
                                />
                            </div>
                            <img
                                className="mt-0"
                                src={Discount}
                                width="80px"
                                alt="Discount"
                            />
                        </div>
                    </div>
                </div>
                {user?.role === "leader" && (
                    <div className="col-12 community_link mb-5">
                        <div className="link_content">
                            <p className="community_heading fs20">
                                Auto share your trade results
                            </p>
                            <p className="fs12">
                                Automatically share every trade result to your
                                social channels.
                            </p>
                        </div>
                        <div className="socialIcons">
                            <div className="block">
                                <img src={X} alt="X" />
                            </div>
                            <div className="block">
                                <img src={Telegram} alt="Telegram" />
                                <img src={Discord} alt="Discord" />
                            </div>
                        </div>
                        <button
                            className="text_GM"
                            onClick={() => {
                                navigate("/leader/performance", {
                                    state: { tab: "autoShare" },
                                })
                                dispatch(setCurrentTab("autoShare"))
                            }}
                        >
                            Set it up
                        </button>
                    </div>
                )}
                <div className="articleRelative">
                    <RenderLinks page="dashboard" />
                </div>
            </div >
            {/* <Link
                className="text-dark d-flex align-items-center links"
                onClick={() => {
                    // localStorage.setItem(
                    //   "lastVisitedRoute",
                    //   window.location
                    //     .pathname
                    // );

                    localStorage.removeItem(
                        "token"
                    );
                    localStorage.removeItem(
                        "lastVisitedRoute"
                    );
                    localStorage.removeItem("rememberMe")
                    // localStorage.clear()
                    dispatch(
                        storeUser({
                            user: null,
                            token: null,
                        })
                    );


                    navigate("/login");
                }}
            >
                {/* <i className="bi bi-box-arrow-right me-2"></i> */}
            {/* Log Out
            </Link> */}
        </>
    );
}
