export const simplifyTradingVolume = (value) => {
  console.log(value, "incoming Value");
  // Ensure the value is a number
  value = Number(value);

  if (value === 0) {
    return "0.00";
  } else if (value >= 1000000) {
    const millionValue = (value / 1000000).toFixed(2); // Round to 2 decimal places
    return millionValue.replace(/.?0$/, "") + "M";
  } else if (value >= 1000) {
    const thousandValue = (value / 1000).toFixed(2); // Round to 2 decimal places
    return thousandValue.replace(/.?0$/, "") + "K";
  } else {
    return value.toFixed(2); // Ensure 2 decimal places for values < 1000
  }
};
