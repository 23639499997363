import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import Launch from "../../../assets/images/2.gif";
import Circle from "../../../assets/images/MoreButton.svg";
import X from "../../../assets/images/x.jpg";
import Telegram from "../../../assets/images/telegram.jpg";
import Discord from "../../../assets/images/discord.svg";
import DailyAvg from "../../../assets/images/1.gif";
import TradeHistoryChart from "../../../components/dashboard-stats/TradeHistoryChart";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";
import OtpInput from "react-otp-input";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { useNavigate } from "react-router-dom";
import moreButton from "../../../assets/images/MoreButton.svg";
import { updateUser } from "../../../redux/users/usersSlice";
import TradeGraph from "../../../assets/images/graph.svg";
import moment from "moment";
import { simplifyTradingVolume } from "../../../helpers/helper";

export default function Trading({ member, setTab }) {
    const [stats, setStats] = useState({});
    const api = useApi();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((value) => value?.user?.user);
    const [date, setDate] = useState("");
    const [show, setShow] = useState(false);
    const community = useSelector(
        (state) => state?.user?.user?.leaderCommunity
    );

    const getUserStats = async () => {
        const response = await api("get", "stats/index");
        console.log(response, "userresponseee")
        if (response?.status) {
            setStats(response?.data);
        } else {
            toastify(response?.message);
        }
    };

    useEffect(() => {
        getUserStats();
    }, []);

    const formatValueWithPlus = (value) => {
        if (value === null) {
            return "0";
        }

        const numericValue = parseFloat(value);
        if (isNaN(numericValue)) {
            return "0";
        }

        return numericValue >= 0
            ? `${numericValue}`
            : numericValue;
    };

    function stringToDateFormat(inputString) {
        if (!inputString) {
            return null;
        }
        const day = parseInt(inputString.substring(0, 2), 10);
        const month = parseInt(inputString.substring(2, 4), 10) - 1;
        const year = parseInt(inputString.substring(4, 6), 10) + 2000;
        const dateObject = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
        return dateObject;
    }

    function dateFormatToString(dateObject) {
        if (!dateObject) {
            return null;
        }
        const day = ("0" + dateObject.getUTCDate()).slice(-2);
        const month = ("0" + (dateObject.getUTCMonth() + 1)).slice(-2);
        const year = dateObject.getUTCFullYear().toString().substring(2, 4);
        const dateString = day + month + year;
        return dateString;
    }

    function isDateInRange(pastDate, currentDateToCheck) {
        const midnightPastDate = new Date(pastDate);
        midnightPastDate.setHours(0, 0, 0, 0);

        const midnightCurrentDateToCheck = new Date(currentDateToCheck);
        midnightCurrentDateToCheck.setHours(0, 0, 0, 0);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return (
            midnightPastDate <= midnightCurrentDateToCheck &&
            midnightCurrentDateToCheck <= currentDate
        );
    }

    useEffect(() => {
        if (community?.statsFrom) {
            setDate(dateFormatToString(new Date(community?.statsFrom)));
        }
    }, [community]);

    useEffect(() => {
        if (
            date?.length === 6 &&
            date !== dateFormatToString(new Date(community?.statsFrom))
            // &&
            // isDateInRange(
            //     new Date(community?.createdAt),
            //     stringToDateFormat(date)
            // )
        ) {
            setTimeout(() => {
                if (!isValidDate()) {
                    setShow(false);
                    return toastify("Please enter valid date")
                } else {

                    setShow(true);
                }
            }, 3000);
        }
    }, [date]);

    console.log(date, "Performacedate")

    const handleStatsDate = async () => {
        console.log(date, "ThisDate")
        if (!isValidDate()) {
            setShow(false);
            return toastify("Please enter valid date")
        }
        try {
            dispatch(setLoading(true));
            setShow(false);
            const response = await api("post", "community/update", {
                statsFrom: new Date(stringToDateFormat(date)),
                id: community?._id,
            });
            if (response?.status) {
                await getUserStats();
                toastify("Date updated successfully.", "success");
                dispatch(setLoading(false));
            } else {
                dispatch(setLoading(false));
                toastify(response?.message || "Unable to change date.");
            }
        } catch (error) {
            console.log({ error });
        }
    };
    const isValidDate = () => {
        const parsedDate = moment(date, 'DDMMYY');
        if (!parsedDate.isValid()) {
            return false
        } else { return true }
    }
    // const isTodayOrLater = () => {
    //     const parsedDate = moment(date, 'DDMMYY');
    //     const givenDate = moment(parsedDate);
    //     const today = moment().startOf('day');
    //     return givenDate.isSame(today, 'day') || givenDate.isAfter(today, 'day');
    // }
    const handleCheckbox = async () => {
        if (!date) {
            return toastify("Please enter date")
        } else if (!isValidDate()) {
            return toastify("Invalid date")
        }
        // else if (!isTodayOrLater()) {
        //     return toastify("Email preference date cannot be a past date")
        // }
        dispatch(setLoading(true));
        const response = await api("post", "community/update", {
            id: user?.leaderCommunity?._id,
            receiveEmailReport: !user?.leaderCommunity?.receiveEmailReport,
        });
        console.log({ response });
        if (response?.status) {
            dispatch(updateUser(response?.data));
            setTimeout(() => {
                dispatch(setLoading(false));
                toastify("Email preference set successfully!", "success");
            }, 2000);
        } else {
            toastify(response?.message);
            dispatch(setLoading(false));
        }
    };

    const calculateBalance = () => {
        let totalBalance =
            parseInt(stats?.bybitBalance) + parseInt(stats?.binanceBalance);
        let weeklyBalance = stats?.balanceSinceLastWeek;

        let total = (weeklyBalance / totalBalance) * 100;

        return `${(total || 0)}%`;
    };

    return (
        <>
            {show && (
                <ConfirmationModal
                    yesAction={handleStatsDate}
                    noAction={() => setShow(false)}
                    text="Are you sure you want to change date?"
                />
            )}
            <div className="stats_cards">
                <div className="statusTrading">
                    <p>
                        Status:{" "}
                        {user?.binanceCredentials?.isConnected ||
                            user?.bybitCredentials?.isConnected
                            ? "Trading"
                            : "Not Trading"}
                    </p>
                    <p>
                        {user?.binanceCredentials?.isConnected &&
                            user?.bybitCredentials?.isConnected
                            ? "Connected to: Binance and ByBit"
                            : user?.bybitCredentials?.isConnected
                                ? "Connected to: ByBit"
                                : user?.binanceCredentials?.isConnected
                                    ? "Connected to: Binance"
                                    : "Not Connected"}
                    </p>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="white_card">
                        <div className="h-100 d-flex flex-column justify-content-center">
                            <p className="text-truncate color-black bold fs19">
                                Your Trading Account
                            </p>
                            <h4 className="text-truncate color-black bold fs30">
                                {"$"}
                                 {(() => {
                                         const simplifiedValue = simplifyTradingVolume(((stats?.binanceBalance || 0) + (stats?.bybitBalance || 0)))
                                         return typeof simplifiedValue === "string" ? simplifiedValue : simplifiedValue.toFixed(2)
                                         })()}
                            </h4>
                            {stats?.balanceSinceLastWeek == undefined ? "" : stats?.balanceSinceLastWeek != 0 ?
                                <p className="text-truncate color-black">
                                    <span className="cyan-color plus fs14">
                                        {formatValueWithPlus(
                                            stats?.balanceSinceLastWeek || 0
                                        )}
                                        {"%"}
                                    </span>{" "}
                                    since last week ($
                                    {(stats?.balanceSinceLastWeek || 0).toFixed(2)})
                                </p>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="black_card">
                        <div className="d-flex h-100 justify-content-between align-items-center">
                            <div className="mbl-100">
                                <p className="text-truncate bold fs19">
                                    Daily Avg. Profit
                                </p>
                                <h4 className="text-truncate bold fs30">
                                    {"$"}
                                    {(() => {
                                         const simplifiedValue = simplifyTradingVolume((stats?.dailyAvgProfit))
                                         return typeof simplifiedValue === "string" ? simplifiedValue : simplifiedValue.toFixed(2)
                                         })()}
                                </h4>
                                {stats?.averageProfitSinceLastWeek == undefined ? "" : stats?.averageProfitSinceLastWeek != 0 ?
                                    <p className="text-truncate">
                                        <span className="plus cyan-color fs14">
                                            {(
                                                stats?.averageProfitSinceLastWeek || 0
                                            ).toFixed(2)}
                                            {"%"}
                                        </span>{" "}
                                        since last week
                                    </p> : ""
                                }
                            </div>
                            <img
                                className="w-60"
                                src={DailyAvg}
                                width="100px"
                                alt="DailyAvg"
                            />
                        </div>
                    </div>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="mbl-100">
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Winrate
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(stats?.winRate || 0).toFixed(2)}
                                    {"%"}
                                </h4>
                            </div>
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Avg. trade
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(() => {
                                         const simplifiedValue = simplifyTradingVolume((stats?.avgTrade))
                                         return typeof simplifiedValue === "string" ? simplifiedValue : simplifiedValue.toFixed(2)
                                         })()}%
                                </h4>
                            </div>
                        </div>
                        <div className="black_card position-relative">
                            <p className="community_heading text-truncate bold fs25">
                                Trade history
                            </p>
                            <p className="text-truncate medium">
                                Get a full overview
                            </p>
                            <img
                                className="graphWidth"
                                src={TradeGraph}
                                alt="graph"
                            />
                            {/* <div style={{ height: "75px", width: "100px" }}>
                                <TradeHistoryChart />
                            </div> */}
                            <div className="arrowBtn moreButtonIcon" onClick={() => {
                                navigate("/leader/performance", {
                                    state: {
                                        tab: "TradeHistory",
                                    },
                                });
                                window.scrollTo({ top: 0, behavior: 'instant' });
                            }}>
                                <img
                                    src={moreButton}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mbl-100">
                        <div className="white_card dark-gray-background position-relative">
                            <p className="community_heading text-truncate bold fs15">
                                Show performance starting from
                            </p>
                            <p className="text-truncate fs12">
                                No input = all of your trading history
                            </p>

                            <OtpInput
                                value={date}
                                onChange={setDate}
                                numInputs={6}
                                inputType="number"
                                containerStyle={"inputDate"}
                                renderInput={(props) => <input {...props} />}
                                placeholder="DDMMYY"
                            />
                            <div className="emailDate">
                                <p className="text-truncate fs12">
                                    Email me the monthly performance report
                                </p>
                                <div className="customCheck">
                                    <input
                                        type="checkbox"
                                        id="myCheckboxTrading"
                                        onChange={handleCheckbox}
                                        checked={
                                            user?.leaderCommunity
                                                ?.receiveEmailReport
                                        }
                                    />
                                    <label
                                        htmlFor="myCheckboxTrading"
                                        className="custom-checkbox custom-checkbox-white"
                                    ></label>
                                </div>
                            </div>
                        </div>
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Last trade
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(stats?.lastTrade || 0).toFixed(2)}%
                                </h4>
                            </div>
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Total trades
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {parseInt(stats?.totalTrades || 0)}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="black_card marginMbl">
                        <div className="d-flex justify-content-center h-100 flex-column">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="mbl-100">
                                    <p className="community_heading text-truncate bold fs19">
                                        Performance YTD
                                    </p>
                                    <h4 className="text-truncate bold fs30">
                                        {(stats?.yearlyGrowth?.performance || 0).toFixed(2)}
                                        %
                                    </h4>
                                    {stats?.thisWeekPnlPercentage == undefined ? "" : stats?.thisWeekPnlPercentage != 0 ?
                                        <p className="text-truncate">
                                            <span className="plus cyan-color fs14">
                                                {" "}
                                                {(
                                                    stats?.thisWeekPnlPercentage ||
                                                    0
                                                ).toFixed(2)}
                                                {"%"}
                                            </span>{" "}
                                            since last week
                                        </p>
                                        : ""
                                    }
                                </div>
                                <img
                                    src={Launch}
                                    className="launch"
                                    width="60px"
                                    alt="launch"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="black_card position-relative">
                        <div className="d-flex justify-content-center h-100 flex-column">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="bold fs19 community_heading lh-25">
                                    Check how your <br /> business is <br />{" "}
                                    performing
                                </p>
                                <div className="arrowBtn moreButtonIcon"
                                    onClick={() => {
                                        navigate("/leader/performance", {
                                            state: {
                                                tab: "business",
                                            },
                                        });
                                        window.scrollTo({ top: 0, behavior: 'instant' });
                                    }}>
                                    <img
                                        src={moreButton}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {user?.role === "leader" && (
                    <div className="col-12 community_link w-692 mx-auto mb-5">
                        <div className="link_content">
                            <p className="community_heading text-uppercase fs20">
                                Auto share your trade results
                            </p>
                            <p className="fs12">
                                Automatically share every trade result to your
                                social channels.
                            </p>
                        </div>
                        <div className="socialIcons">
                            <div className="block">
                                <img src={X} alt="X" />
                            </div>
                            <div className="block">
                                <img src={Telegram} alt="Telegram" />
                                <img src={Discord} alt="Discord" />
                            </div>
                        </div>
                        <button
                            className="text_GM"
                            onClick={() => setTab("autoShare")}
                        >
                            Set it up
                        </button>
                    </div>
                )}
                <div className="w-692 mx-auto articleRelative tradeArticle">
                    <RenderLinks page="performanceTrading" />
                </div>
            </div>
        </>
    );
}
